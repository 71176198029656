/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Produkty značky Profumel"}>
        <Column className="pb--60 pt--60" layout={"l9"} name={"kw26788b9a"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image style={{"maxWidth":253}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/6a6f6eade8e9428f993ef5d855fac30f_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/6a6f6eade8e9428f993ef5d855fac30f_s=350x_.png 350w, https://cdn.swbpg.com/t/35535/6a6f6eade8e9428f993ef5d855fac30f_s=660x_.png 660w, https://cdn.swbpg.com/t/35535/6a6f6eade8e9428f993ef5d855fac30f_s=860x_.png 860w, https://cdn.swbpg.com/t/35535/6a6f6eade8e9428f993ef5d855fac30f_s=1400x_.png 1400w"}>
              </Image>

              <Text className="text-box text-box--left pl--0" style={{"maxWidth":562}} content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l4"} name={"twkm36w3k2e"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" style={{"maxWidth":528}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/48c5eddc7d6743aeb7276d8907f23318_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/35535/48c5eddc7d6743aeb7276d8907f23318_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/48c5eddc7d6743aeb7276d8907f23318_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/48c5eddc7d6743aeb7276d8907f23318_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/48c5eddc7d6743aeb7276d8907f23318_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/35535/48c5eddc7d6743aeb7276d8907f23318_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box fs--12" content={"<span style=\"color: var(--black);\">Parfém do praní Profumel Baby Dream vám přinese intenzivní zážitek nádherné vůně prádla při každém praní. Jeho výjimečnost spočívá v jemném složení, které je dezinfekční a zároveň hypoalergenní, teda vhodné i na dětské prádlo.\n\nJedná se o vysoce koncentrovanou parfémovou esencii s italskými esenciálními oleji, která zajistí vašemu oblečení dlouhotrvající svěží aroma. Kromě prádla navíc příjemně provoníte celý domov\n\nPřípravek je náhradou aviváže se složením, které je šetrné k pokožce a bezpečné pri jakémkoli druhu praní. Produkt je vhodný i na použití v sušičce a ruční praní. Máte - li tvrdou vodu a chcete přidat i běžnou aviváž, doporučujeme vybrat neutrální vůni.\n\nProdukty Profumel obsahují vysokou dávku koncentrátů, díky čemuž stačí používat opravdu malé množství přípravku, takže jsou šetrné nejen k vaší pračce, ale také k vaší peněžence.\n\nParfém do praní Profumel Baby Dream\nTento vonný olej do praní s dezinfekčním a hypoalergenním složením vyniká jemnou vůní. Srdcem koncentrátu sa nesou esence růže a lotosového květu v kombinaci s tóny, které připomínají intenzivní vůni ambry. Další složkou jsou tóny malín a citrusů, uzavřené sladkou vanilkou a bílým pižmem vyváženým santalovým dřevem.\n<br>&nbsp;Dávkování\npři praní v pračce – do zásobníku na aviváž přidejte 10 ml přípravku na každých 8 – 10 kg prádla\npři použití v sušičce – před sušením navlhčete bavlněný hadřík s 5 ml přípravku a přidejte jí k náplni\npři ručním praní – do vody přidejte 10 ml parfému\nBalení\n250 ml</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" style={{"maxWidth":766}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/81d93f9f0cac4095adf45ce7135d648d_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/35535/81d93f9f0cac4095adf45ce7135d648d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/81d93f9f0cac4095adf45ce7135d648d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/81d93f9f0cac4095adf45ce7135d648d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/81d93f9f0cac4095adf45ce7135d648d_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/35535/81d93f9f0cac4095adf45ce7135d648d_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box fs--12" content={"<span style=\"color: var(--black);\">Parfém do praní Profumel Blue Diamond vám přinese intenzivní zážitek nádherné vůně prádla při každém praní.\n\nJde o vysoce koncentrovanou parfémovou esenci s italskými esenciálními oleji, která zajistí vašemu oblečení dlhotrvající svěží aroma. Kromě prádla navíc příjemně provoníte celý domov.\n\nPřípravek je náhradou aviváže se složením, které je šetrné k pokožce a bezpečné při jakémkoli druhu praní. Produkt je vhodný i na použití v sušičce a ruční praní. Pokud máte tvrdou vodu a chcete pridat i běžnou aviváž, doporučujeme vybrat neutrální vůni.\n\nProdukty Profumel obsahují vysokou dávku koncentrátů, díky čemu stačí používat opravdu malé množství přípravku, takže jsou šetrné nejen k vaší pračce, ale i k vaší peněžence..\n\nParfém do praní Profumel Blue Diamond\nTento vonný olej do praní je orientální harmonií mezi květinovými, pižmovými a jantarovými tóny s bergamotom a liči, které se představují ve vstupní časti vůně. V srdci pokračují křehké fialky s kaméliemi a sladký jasmín. Celou kompozici uzavírá základ z guajakového dřeva a bílého pižma s ambrovými tóny.\n<br><br><br>&nbsp;Dávkování\npři praní v pračce – do zásobníku na aviváž přidejte 10 ml přípravku na každých 8 – 10 kg prádla\npři použití v sušičce – před sušením navlhčete bavlněný hadřík s 5 ml přípravku a pridejte ho k náplni\npři ručním praní – do vody přidejte 10 ml parfému\nBalení\n250 ml</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" style={{"maxWidth":468}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/12b661f29ac84c1dba468df4f47cab50_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/35535/12b661f29ac84c1dba468df4f47cab50_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/12b661f29ac84c1dba468df4f47cab50_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/12b661f29ac84c1dba468df4f47cab50_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/12b661f29ac84c1dba468df4f47cab50_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/35535/12b661f29ac84c1dba468df4f47cab50_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box fs--12" content={"<span style=\"color: var(--black);\">Parfém do praní Profumel Fresh Green vám přinese intenzivní zážitek nádherné vůně prádla při každém praní.\n\nJde o vysoce koncentrovanou parfémovou esenci s italskými esenciálními oleji, která zajistí vašemu oblečení dlouhotrvající svěží aroma. Kromě prádla navíc příjemně provoníte celý domov.\n\nPřípravek je náhradou aviváže se složením, které je šetrné k pokožce a bezpečné při jakémkoli druhu praní. Produkt je vhodný i k použití v sušičce a ruční praní. Pokud máte tvrdou vodu a chcete pridat i bežnou aviváž, doporučujeme vybrat neutrální vůni.\n\nProdukty Profumel obsahují vysokou dávku koncentrátů, díky čemu stačí používat opravdu malé množství přípravku, takže jsou šetrné nejen k vaší pračce, ale i k vaší peněžence.\n\nParfém do praní Profumel Fresh Green\nTento vonný olej do praní nabízí sladké a zároveň svěží tóny léta. Na pozadí pomerančového květu se rozvíjí bergamot a mandarinka, které přecházejí do srdce s teplými exotickými květy na čele s jasmínem, tuberózou a tiaré.\n<br><br><br><br>&nbsp;Dávkování\npři praní v pračce – do zásobníku na aviváž přidejte 10 ml přípravku na každých 8 – 10 kg prádla\npři použití v sušičce – před sušením navlhčete bavlněný hadřík s 5 ml přípravku a přidejte jí k náplni\npři ručním praní – do vody přidejte 10 ml parfému\nBalení\n250 ml</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l4"} name={"ld35yzgm4k"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" style={{"maxWidth":758}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/5acf2d71bb1e4c83bc61f16a2ad5776d_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/35535/5acf2d71bb1e4c83bc61f16a2ad5776d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/5acf2d71bb1e4c83bc61f16a2ad5776d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/5acf2d71bb1e4c83bc61f16a2ad5776d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/5acf2d71bb1e4c83bc61f16a2ad5776d_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/35535/5acf2d71bb1e4c83bc61f16a2ad5776d_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box fs--12" content={"<span style=\"color: var(--black);\">Parfém na praní Profumel New Moon vám přinese intenzivní zážitek nádherné vůně prádla při každém praní.\n\nJde o vysoce koncentrovanou parfémovú esenci s italskými esenciálními oleji, která zajistí vašemu oblečení dlouhotrvající svěží aroma. Kromě prádla navíc příjemně provoníte celý domov.\n\nPřípravek je náhradou aviváže se složením, které je šetrné k pokožce a bezpečné při jakémkoli druhu praní. Produkt je vhodný i na použití v sušičce a ruční praní. Máte - li  máte tvrdou vodu a chcete pridat i běžnou aviváž, doporučujeme vybrat neutrální vůni.\n\nProdukty Profumel obsahují vysokou dávku koncentrátů, díky čemu stačí používat opravdu malé množství přípravku, takže jsou šetrné nejen k vaší pračce, ale i k vaší peněžence.\n\nParfém do praní Profumel New Moon\nTento vonný olej do praní s dezinfekčním složením je inspirovaný šumivými tóny šeříku a červeného ovoce. Srdce vůné charakterizují růže s bramboříkem a základní tóny zase aromatické smyslné cedrové dřevo a guajak.\n<br><br><br>¨<br><br>&nbsp;Dávkování\npři praní v pračce – do zásobníku na aviváž přidejte 10 ml přípravku na každých 8 – 10 kg prádla\npri použití v sušičce – před sušením navlhčete bavlněný hadřík s 5 ml přípravku a přidejte ju k náplni\npri ručním praní – do vody přidejte 10 ml parfému\nBalení\n250 ml</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" style={{"maxWidth":640}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/c04210aaa61745fcb639802393af4906_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/35535/c04210aaa61745fcb639802393af4906_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/c04210aaa61745fcb639802393af4906_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/c04210aaa61745fcb639802393af4906_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/c04210aaa61745fcb639802393af4906_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/35535/c04210aaa61745fcb639802393af4906_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box fs--12" content={"<span style=\"color: var(--black);\">Parfém do praní Profumel Peony vám přinese intenzivní zážitek nádherné vůně prádla při každém praní.\n\nJde o vysoce koncentrovanouý parfémovou esenci s italskými esenciálními oleji, která zajistí vašemu oblečení dlhotrvající svěží aroma. Kromě prádla navíc příjemně provoníte celý domov.\n\nPřípravek je náhradou aviváže se složením, které je šetrné k pokožce a bezpečné při jakémkoli druhu praní. Produkt je vhodný i k použití v sušičce a ruční pranií. Pokud máte tvrdou vodu a chcete pridat i běžnou aviváž, doporučujeme vybrat neutrální vůni.\n\nProdukty Profumel obsahují vysokou dávku koncentrátů, díky čemuž stačí používat opravdu malé množstvoí přípravku, takže jsou šetrné nejen k vaší pračce, ale i k vaší peněžence.\n\nParfém do praní Profumel Peony\nTento vonný olej do praní přináší rafinovanou a zároveň jemnou vůni se sladkými a ovocnými tóny. V popředí sa nachází mandarinka s broskví a mandlovým květem, které přecházejí do květinového srdce plného pivoňky, tuberózy a magnólie. Esenci uzavírá teplý plný zemitý základ.\n<br><br><br><br>&nbsp;Dávkování\npři praní v pračce – do zásobníku na aviváž přidejte 10 ml přípravku na každých 8 – 10 kg prádla\npři použití v sušičce – před sušením navlhčete bavlněný hadřík s 5 ml přípravku a přidejte ho k náplni\npři ručním praní – do vody přidejte 10 ml parfému\nBalení\n250 ml</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" style={{"maxWidth":456}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/06bea6f2f2344146b9b0a3aa9e4232ad_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/35535/06bea6f2f2344146b9b0a3aa9e4232ad_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/06bea6f2f2344146b9b0a3aa9e4232ad_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/06bea6f2f2344146b9b0a3aa9e4232ad_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/06bea6f2f2344146b9b0a3aa9e4232ad_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/35535/06bea6f2f2344146b9b0a3aa9e4232ad_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box fs--12" content={"<span style=\"color: var(--black);\">Parfém do praní Profumel Soft Melody vám přinese intenzivní zážitek nádherné vůně prádla při každém praní. Jeho výjimečnost spočívá v jemném složení, které je dezinfekční a zároveň hypoalergenní, teda vhodné i na dětské prádlo.\n\nJde o vysoce koncentrovanou parfémovou esenci s italskými esenciálními oleji, která zajistí vašemu oblečení dlhotrvající svěží aroma. Kromě prádla navíc příjemně provoníte celý domov.\n\nPřípravek je náhradou aviváže se složením, které je šetrné k pokožce a bezpečné při jakémkoli druhu praní. Produkt je vhodný i na použití v sušičce a ruční praní. Máte - li  tvrdou vodu a chcete přidat i běžnou aviváž, doporučujeme vybrat neutrální vůni.\n\nProdukty Profumel obsahují vysokou dávku koncentrátů, díky čemu stačí používat opravdu malé množství přípravku, takže jsou šetrné nejen k vaší pračce, ale i k vaší peněžence.\n\nParfém do praní Profumel Soft Melody\nTento vonný olej do praní s dezinfekčním a hypoalergenním složením spojuje harmonii hned několika bohatých vůní. Otevírá se osvěžujícími citrusy, které přecházejí ke klasické růži, k výrazným tónům pomerančového květu a čistého bílého květu. Vše uzavírá sladká vanilka kombinovaná s dřevitými vůněmi a bílým pižmem.\n<br><br>&nbsp;Dávkování\npři praní v pračce – do zásobníku na aviváž přidejte 10ml přípravku na každých 8 - 10kg prádla\npři použití v sušičce – před sušením navlhčete bavlněný hadřík s 5 ml přípravku a pridejte ho k náplni\npři ručním praní – do vody přidejte 10 ml parfému\nBalení\n250 ml</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l4"} name={"h16hgq26o4t"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" style={{"maxWidth":260}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/eacba936a24b4fcb8278bb1e70ccbc26_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/35535/eacba936a24b4fcb8278bb1e70ccbc26_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/eacba936a24b4fcb8278bb1e70ccbc26_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/eacba936a24b4fcb8278bb1e70ccbc26_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/eacba936a24b4fcb8278bb1e70ccbc26_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/35535/eacba936a24b4fcb8278bb1e70ccbc26_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box fs--12" content={"<span style=\"color: var(--black);\">Parfém do praní Profumel Sweet Sun vám přinese intenzivní zážitek nádherné vůně prádla při každém praní.\n\nJde o vysoce koncentrovanou parfémovou esenci s italskými esenciálními oleji, která zajistí vašemu oblečení dlouhotrvající svěží aroma. Kromě prádla navíc příjemně provoníte celý domov.\n\nPřípravek je náhradou aviváže se složením, které je šetrné k pokožce a bezpečné při jakémkoli druhu praní. Produkt je vhodný i k použití v sušičce a ruční praní. Pokud máte tvrdou vodu a chcete přidat aj běžnou aviváž, doporučujeme vybrat neutrální vůni.\n\nProdukty Profumel obsahují vysokou dávku koncentrátů, díky čemu stačí používat opravdu malé množství přípravku, takže jsou šetrné nejen k vaší pračce, ale i k vaší peněžence.\n\nParfém do praní Profumel Sweet Sun\nTento vonný olej do praní s dezinfekčním složením vás upoutá intenzívním květinovým aroma. Srdcem vůně sa nese magnolie, které předcházejí citrusové tóny citrónů Sorrento, balzámiko s eukalyptem a pižmo s květem konvalinky. Zakončná je santalovým dřevem a pačuli, které jsou obohacené o sladkou vůni růžového dřeva.\n\npři praní v pračce – do zásobníku na aviváž přidejte 10 ml přípravku na každých 8 – 10 kg prádla\npři použití v sušičce – před sušením navlhčete bavlněný hadřík s 5 ml přípravku a přidejte ho k náplni\npři ručním praní – do vody přidejte 10 ml parfému\nBalení\n250 ml</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"[[UNIgenericsection2]]"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}